<template>
  <b-card
    v-if="data"
    no-body
    class="card-statistics"
  >
    <b-card-header>
      <b-card-title>Statistics</b-card-title>
      <b-card-text class="font-small-2 mr-25 mb-0">
        Updated 1 month ago
      </b-card-text>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col
          v-for="(item, index) in data"
          :key="item.icon"
          xl="3"
          sm="6"
          :class="item.customClass"
        >
          <b-media no-body>
            <b-media-aside

              class="mr-2"
            >
              <b-avatar
                size="48"
                :variant="item.color"
              >
                <feather-icon
                  size="24"
                  :icon="item.icon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ countsData[index].count.toFixed() }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ countsData[index].name }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'
import { db } from "@/main";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  // created() {
  //   alert('test');
  //   console.log(data)
  // }
  data() {
    return {
      count: '',
      name: '',
      countsData: {},
      reveneuReports: '',
      PurchaseSubscription: '',
      employeesData: '',
      totalSell: '',
    }
  },
  created() {
    // data
    // this.users();
    this.readEmployees();
    this.revenueReport();
    this.subscriptionPurchase();
  },

  methods: {
    readEmployees() {
      var i = 1;
      this.countsData = [];
      db.collection("users")
        .get()
        .then((querySnapshot) => {
          this.employeesData = querySnapshot.size;
          // console.log('Total Users =>', this.employeesData);
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    },

    revenueReport() {
      var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        this.revenueTotalAmount = 0;
        this.companySharing = 0;
        this.NewMappingMonth = [];

        this.newMonths = [];
        db.collection("offer_purchase")
        .orderBy('created', 'asc')
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.date = new Date(doc.data().created);
              this.newMonths.push(months[this.date.getMonth()]);
              this.NewMappingMonth = removeDuplicates(this.newMonths)
              function removeDuplicates(newMonths) {
                return newMonths.filter((item,
                  index) => newMonths.indexOf(item) === index);
              }

              this.newIndex = [];
              months.forEach((value, index) => {
                if (value == months[this.date.getMonth()]) {
                  this.revenueTotalAmount += doc.data().amount / 100;
                  this.companySharing += doc.data().company_sharing_amount / 100;
                }
              })

            })
            console.log(this.revenueTotalAmount)
          })
    },

    subscriptionPurchase() {
      var i = 1;
      this.totalSell = 0;
      this.tags = [];
      this.countsData = [];
      db.collection("PurchaseSubscription")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            if(doc.data().cancelled != true || !doc.data().cancelled){
              this.tags.push(doc.data())
              // console.log('count: ', this.tags.length);
            this.PurchaseSubscription = this.tags.length;
            // console.log(doc.data());
            this.totalSell += doc.data().PurchaseSubscriptionData.amount;
            }
          })
          // console.log('Total Purchased Count =>', this.PurchaseSubscription);
          // console.log('Total Sell =>', this.totalSell)
          this.countsData.push({
            count: this.totalSell,
            name: 'Sales'
          });
          this.countsData.push({
            count: this.employeesData,
            name: 'Customers'
          });
          this.countsData.push({
            count: this.PurchaseSubscription,
            name: 'Purchase Product'
          });
          this.countsData.push({
            count: this.revenueTotalAmount,
            name: 'Pusechased Offers Revenue'
          });
          // console.log(this.countsData)
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    },
  }
}
</script>
