<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <!-- <b-col
        xl="4"
        md="6"
      >
        <ecommerce-medal :data="data.congratulations" />
      </b-col> -->
      <b-col xl="12" md="12">
        <ecommerce-statistics :data="data.statisticsItems" />
      </b-col>
    </b-row>
 <div style="
    background-color: white;
"> 
    <h2 style="
    padding: 15px;
">Admin  Data</h2>
    <ul>
      <li v-for="(doc, index) in adminAmounts" :key="index">
        <!-- Render your document data here -->
        <!-- <p class="custom-paragraph">Total Earning: {{ doc.amount }}</p> -->
        <p class="custom-paragraph" style="
    padding: 10px;
">Total Earning: {{ Math.round(doc.amount) }}</p>
      </li>
    </ul>
  </div>
    <!-- <b-row class="match-height">
      <b-col lg="4">
        <b-row class="match-height">
          <b-col lg="6" md="3" cols="6">
            <ecommerce-order-chart :data="data.statisticsOrder" />
          </b-col> -->
    <!--/ Bar Chart - Orders -->
    <!-- <b-col lg="6" md="3" cols="6"> 
            <ecommerce-profit-chart :data="data.statisticsProfit" />
          </b-col> -->
    <!-- <b-col lg="12" md="6">
            <ecommerce-earnings-chart :data="data.earningsChart" />
          </b-col>
        </b-row>
      </b-col> -->

    <!-- Revenue Report Card -->
    <b-col xl="12" md="12">
      <ecommerce-revenue-report v-if="this.arr.revenue" :data="this.arr.revenue" />
    </b-col>
    <!--/ Revenue Report Card -->
    <!-- </b-row> -->

    <!-- <b-row class="match-height"> -->
    <!-- Company Table Card -->
    <!-- <b-col lg="8">
        <ecommerce-company-table :table-data="data.companyTable" />
      </b-col> -->
    <!--/ Company Table Card -->

    <!-- Developer Meetup Card -->
    <!-- <b-col  lg="4" md="6">
        <ecommerce-meetup :data="data.meetup" />
      </b-col> -->
    <!--/ Developer Meetup Card -->

    <!-- Browser States Card -->
    <!-- <b-col lg="4" md="6">
        <ecommerce-browser-states />
      </b-col> -->
    <!--/ Browser States Card -->

    <!-- Goal Overview Card -->
    <!-- <b-col lg="4" md="6">
        <ecommerce-goal-overview :data="data.goalOverview" />
      </b-col> -->
    <!--/ Goal Overview Card -->

    <!-- Transaction Card -->
    <!-- <b-col lg="4" md="6">
        <ecommerce-transactions :data="data.transactionData" />
      </b-col> -->
    <!--/ Transaction Card -->
    <!-- </b-row> -->
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import { getUserData } from '@/auth/utils'
import EcommerceMedal from './EcommerceMedal.vue'
import EcommerceStatistics from './EcommerceStatistics.vue'
import EcommerceRevenueReport from './EcommerceRevenueReport.vue'
import EcommerceOrderChart from './EcommerceOrderChart.vue'
import EcommerceProfitChart from './EcommerceProfitChart.vue'
import EcommerceEarningsChart from './EcommerceEarningsChart.vue'
import EcommerceCompanyTable from './EcommerceCompanyTable.vue'
import EcommerceMeetup from './EcommerceMeetup.vue'
import EcommerceBrowserStates from './EcommerceBrowserStates.vue'
import EcommerceGoalOverview from './EcommerceGoalOverview.vue'
import EcommerceTransactions from './EcommerceTransactions.vue'
import { db } from "@/main";

export default {
  components: {
    BRow,
    BCol,

    EcommerceMedal,
    EcommerceStatistics,
    EcommerceRevenueReport,
    EcommerceOrderChart,
    EcommerceProfitChart,
    EcommerceEarningsChart,
    EcommerceCompanyTable,
    EcommerceMeetup,
    EcommerceBrowserStates,
    EcommerceGoalOverview,
    EcommerceTransactions,
  },
  data() {
    return {
      data: {},
      arr: {},
        adminAmounts: [] ,
    }
  },
   mounted() {
    this.fetchAdminAmount();
  },
    methods: {
    fetchAdminAmount() {
      db.collection('adminamount')

        .get()
        .then((snapshot) => {
          // Clear previous data before updating
          this.adminAmounts = [];
          
          snapshot.forEach((doc) => {
            // Push fetched data to the array
            this.adminAmounts.push({ id: doc.id, ...doc.data() });
          });
        })
        .catch((error) => {
          console.error("Error fetching admin amount:", error);
        });
    }
  },
  created() {
    document.title = "Dashboard",
    this.$http.get('/ecommerce/data')
      .then(response => {
        this.data = response.data
        console.log(this.data.revenue)
        var revenueChart = this.data.revenue.revenueReport.series
        var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        // alert('revenue sdasd')
        var i = 0;
        this.totalAmount = 0;
        this.companySharing = 0;
        this.getmonths = [];
        this.NewMappingMonth = [];
        this.data.revenue.revenueReport.series[0].data = [];
        this.data.revenue.revenueReport.series[1].data = [];

        this.newMonths = [];
        db.collection("offer_purchase")
        .orderBy('created', 'asc')
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.date = new Date(doc.data().created);
              this.newMonths.push(months[this.date.getMonth()]);
              this.NewMappingMonth = removeDuplicates(this.newMonths)
              function removeDuplicates(newMonths) {
                return newMonths.filter((item,
                  index) => newMonths.indexOf(item) === index);
              }

              this.collectAmount = [];
              this.companyAmount = [];
              this.newIndex = [];
              months.forEach((value, index) => {
                if (value == months[this.date.getMonth()]) {
                  this.totalAmount += doc.data().amount / 100;
                  this.companySharing += doc.data().company_sharing_amount / 100;
                  this.data.revenue.revenueReport.series[0].data[index] = this.totalAmount;
                  this.data.revenue.revenueReport.series[1].data[index] = this.companySharing;
                  this.newIndex = index - 1;
                }
              })

            })
            console.log(this.NewMappingMonth)
            this.data.revenue.budgetChart.series[2] = this.NewMappingMonth;

            this.collectAmount = this.data.revenue.revenueReport.series[0].data
            this.companyAmount = this.data.revenue.revenueReport.series[1].data
            console.log(this.companyAmount);
            console.log(this.collectAmount);
            // Intro Revenue Calculate
            this.originalTotal = [];
            this.collectAmount.forEach((value, index) => {
              if (index - 1 == -1) {
                  console.log(index)
                  this.originalTotal.push(value)
              }else{
                this.originalTotal.push(value - this.collectAmount[index - 1])
              }
            })
            this.originalTotal.forEach((value, i) => {
              this.data.revenue.revenueReport.series[0].data[i] = value.toFixed();
            })

            // Company Revenue Calculate
            this.CompanyoriginalTotal = [];
            this.companyAmount.forEach((value, index) => {
              if (index - 1 == -1) {
                this.CompanyoriginalTotal.push(value)
              }else{
                this.CompanyoriginalTotal.push(value - this.companyAmount[index - 1])
              }
            })

            this.CompanyoriginalTotal.forEach((value, i) => {
              this.data.revenue.revenueReport.series[1].data[i] = -value.toFixed();
            })
            this.arr = this.data;
            console.log("revunue data =>", this.arr.revenue);
            console.log("revunue data =>", this.data.revenue);

          })
          .catch((error) => {
            console.log("Error getting documents: ", error);
          });
        const userData = getUserData()
        this.data.congratulations.name = userData.fullName.split(' ')[0] || userData.username
      })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
.custom-paragraph {

  padding: 1px;
}
</style>
